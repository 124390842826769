<template lang="pug">

  .home.mt-1
    b-card()
      .row
          .d-none.d-lg-inline-block.col-5.col-lg-5
              b-list-group(size="sm").user-list-group 
                  b-list-group-item(@click="openEvaluation(null)" :class="{active: !currentUser}")
                      b Overall Average

              b-list-group().user-list-group 
                  b-list-group-item(@click="openEvaluation($store.state.user)" :class="{active: currentUser==$store.state.user}")
                      User(:user="$store.state.user", :show-name="true", :popover="false", :size="25").small

              b-list-group().user-list-group 
                  b-list-group-item(v-for="user in $store.state.users", @click="openEvaluation(user)" :class="{active: currentUser==user}")
                      User(:user="user", :show-name="true", :popover="false" :size="25").small
          .col-12.col-lg-7
            label(v-if="!currentUser") Overall evalulation (average)
            label(v-if="currentUser").text-muted Evaluation of {{currentUser.full_name}}
          
            table.table

              tr(v-for="field in currentEvaluation.data_fields")
                td 
                  label {{field.label}}
                td 
                  template(v-if="currentUser == $store.state.user")
                    template(v-if="field.data_type=='slider'") 
                      b-form-input(type="range", v-model="currentVote[field.name]", size="sm", :min="1", :step="1", :max="10").small
             
                    template(v-if="field.data_type=='textarea'")
                      b-textarea(v-model="currentVote[field.name]", :placeholder="field.description")
                    template(v-if="field.data_type=='text'", :placeholder="field.description")
                      b-input(v-model="currentVote[field.name]")
                    template(v-if="field.data_type=='select'")
                      v-select(v-model="currentVote[field.name]", :options="field.options", :placeholder="field.description")
                  
                  template(v-if="currentUser != $store.state.user")
                    template(v-if="field.data_type=='slider'") 
                      
                      b-progress(:value="currentVote[field.name]" :max="10" show-value, style="min-width: 180px")
                    template(v-else)
                      | {{ currentVote[field.name]  }}
                td 
                  template(v-if="currentUser == $store.state.user")
                    | {{currentEvaluation[k]}}
      
      .row(v-if="currentUser == $store.state.user")
        .col-12
          .float-right
            b-btn(variant="primary", size="sm") Save
                  
              
        
</template>

<script>
// @ is an alias to /src

// import Vue from 'vue'

//import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'
import User from '@/components/User'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Evaluations',
  props: ['currentEvaluation'],
  data: function () {
    return {
        currentVote: {}
    }
  },

  mounted: function() {
      this.$store.dispatch("get_users");
      this.openEvaluation(null);
  },


   computed: {

        ...mapFields([
            'query.keywords'
        ]),
  },

  methods: {
    openEvaluation(user) {
        this.currentUser = user;
        this.currentEvaluation.data_fields.forEach((x) => {
          this.currentVote[x.name] =  Math.floor(Math.random() * Math.floor(8)) + 2;
        });
     
       
        console.log(this.currentVote);
        this.$forceUpdate();
      }
  },


  components: { 
      SearchFilter,
      User
  }
}

</script>
<style lang="scss" scoped>
.user-list-group {
    cursor: pointer;
    margin-bottom: 0px;
    max-height: 250px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    
    li { 
      padding: 0px !important;
    }
}
</style>