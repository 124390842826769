<template lang="pug">
  .search-filter
    b-form(v-if="$store.getters.project && $store.getters.project.sheets")
      
        b-card().shadow-sm.mt-1
    
            b-form-group(id="input-group-1", label="Similar to", label-for="input-1", @click="", label-size="sm")
                .form-group-wrap(v-if="$store.getters.project")
                    b-select(:options="['No similarity search', 'Project: ' + $store.getters.project.sheets[0].substring(0,20), 'Company list']", v-model="selected", size="sm")
                    b-textarea(placeholder="List of similar companies", v-model="domains",  v-show="selected=='Company list'" rows="3", max-rows="5", size="sm")#smilar.mt-2

            //
              b-form-group(id="input-group-1", label="Query", label-for="input-1", @click="", label-size="sm")
                  
                  b-textarea(placeholder="Query", v-model="q",  rows="2", max-rows="4", size="sm").mt-2

              hr.mt-4.mb-3

              b-form-group(id="input-group-2", label="Country", label-for="input-2", label-size="sm")
                  b-input(size="sm")
              
              b-form-group(id="input-group-2", label="Region", label-for="input-2", label-size="sm")
                  b-input(size="sm")
              
              b-form-group(id="input-group-2", label="City", label-for="input-2", label-size="sm")
                  b-input(size="sm")
    
            hr.mt-4.mb-3

            .float-right
                b-button(variant="primary", @click="getData()", size="sm")#run-btn Search

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SearchFilter',
  
  data: function () {
    return {
  
    }
  },

  mounted: function() {
  
      this.host = Config.config.base_host;
      if(!this.selected) {
        this.selected = "No similarity search";
      }
      
  },

       computed: {

        ...mapFields([
            'query.selected',
            'query.keywords',
            'query.domains',
            'query.q',
            'query.similar_list'
        ]),
  },
  
  methods: {
    
    getData() {
       
        if(this.selected.indexOf("No sim") > -1) {
          
          this.$store.dispatch("search");
        } else if(this.selected.indexOf("Project:") > -1) {
          console.log(this.$store.state.query.domains);
          this.domains = this.$store.getters.project.data.map(x=>x.domain).join("\n");
          this.$forceUpdate();
          this.$store.dispatch("search_similar"); 
        } else {
          
          this.$store.dispatch("search_similar");
        }
                
        //this.display();
    },

  },
  components: {
    
  }
}

</script>
<style lang="scss" scoped>
/*.search-filter {
    position: fixed;
    margin-top: -50px;
    z-index: 100000;
}*/
</style>
