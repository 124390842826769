<template lang="pug">

  .home   
    b-overlay(:show="loading")
      .row(v-if="form && configuration")
   
        .col-12
          div(id="listgroup-ex" data-target="#list-example")
           
            div(:id="k" v-for="(fields, k) in custom_groups").mb-5
              label().mr-5 {{k}} 
              .float-right.small

                b-link(@click="edit_toggle(k)" v-if="!edit_states[k]", size="sm").gray-info
                  <i class="fas fa-pen"></i>
                b-link(@click="edit_toggle(k)" v-if="edit_states[k]", size="sm").text-muted
                  <i class="fas fa-pen"></i>

                

              b-card(@click="edit_toggle(k, true)")
                
                .mb-3(v-for="field in fields")
                
                  b-form-group(label-cols-sm="4", :label="field.label") 
                 
                    template(v-if="field.data_type=='slider'") 
                      b-input-group(style="max-width: 400px", size="sm")
                        b-form-input(type="range", v-model="form.custom_data[field.name]", size="sm", :min="1", :step="1", :max="10", :disabled="!edit_states[k]")
                        b-input(v-model="form.custom_data[field.name]", :disabled="true", style="max-width: 40px", size="sm")
                    template(v-if="field.data_type=='textarea'")
                      b-textarea(v-model="form.custom_data[field.name]", :placeholder="field.name['description']", :disabled="!edit_states[k]", size="sm")
                    template(v-if="field.data_type=='text'", )
                      b-input(v-model="form.custom_data[field.name]",:placeholder="field.name['description']", :disabled="!edit_states[k]", size="sm")
                    template(v-if="field.data_type=='money_amount'", :placeholder="field.name['description']")
                      currency-input(v-model="form.custom_data[field.name]", :disabled="!edit_states[k]", size="sm")
                    template(v-if="field.data_type=='date'",)
                      b-form-datepicker(v-model="form.custom_data[field.name]", :placeholder="field.name['description']", :disabled="!edit_states[k]", size="sm")
                    template(v-if="field.data_type=='select'")
                      v-select(size="sm", v-model="form.custom_data[field.name]", :options="field.options", :placeholder="field.name['description']", :disabled="!edit_states[k]")
                   
                .mt-3.float-right(v-if="edit_states[k]")
                  b-btn(variant="link", size="sm", @click.stop="edit_toggle(k)").mr-1 Cancel
                  b-btn(variant="primary", size="sm", @click="save()") Save
              
             
            
            //template(v-for="ev in this.evaluations")
              label.mt-5(id="business") {{ev.description}}
              Evaluations(:currentEvaluation="ev")
            
          

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'

import ProjectCard from '@/components/ProjectCard'
import Evaluations from '@/views/orgs/Evaluations'
import ProjectStageSelect from '@/components/ProjectStageSelect'


export default {
  
  name: 'Analytics',
  
  props: ["organization_id", "configuration"],

  data: function () {
    return {
     form: null,
     conf: null,
     custom_groups: {},
     edit_states: {},
     loading: false
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.configuration.data_fields.forEach((x)=> {
      if(!this.custom_groups[x.group]) {
        this.custom_groups[x.group] = []
        this.edit_states[x.group] = false;
      }
      this.custom_groups[x.group].push(x);
    });
   
    Vue.http.get(`${Config.config.base_host}/organizations/${this.organization_id}`).then(res => {
        this.loading = false;
        this.form = res.body;
        
    });
  
  },

   watch: {
        "organization_id": function(val) {
            this.loading = true;
            Vue.http.get(`${Config.config.base_host}/organizations/${val}`).then(res => {
                this.loading = false;
                this.form = res.body;
            });
        },
        "configuration": function() {
            
            this.configuration.data_fields.forEach((x)=> {
              if(!this.custom_groups[x.group]) {
                this.custom_groups[x.group] = []
                this.edit_states[x.group] = false;
              }
              this.custom_groups[x.group].push(x);
            });
           
        },
  },


  methods: {
      edit_toggle(k, cover = false) {

        if(cover && this.edit_states[k]) return;

        let new_state = !this.edit_states[k]
        
        for(let i in this.edit_states) {
          
          this.edit_states[i] = false;
         
        }

        console.log(new_state, this.edit_states); 

        if(!this.edit_states[k]) {
          this.edit_states[k] = new_state;
          if(!new_state) {
            this.edit_states[k] = false;
            this.$emit("reload")
          }
        }

        this.$forceUpdate();
    },

     save() {
      this.$store.dispatch('start_loading');
      Vue.http.post(`${Config.config.base_host}/organizations`, this.form).then(res => {
         
          this.form = res.body;
          this.$store.dispatch('stop_loading');
          for(let i in this.edit_states) {
            this.edit_states[i] = false;
          }
          this.$emit('saved', res.body);
      });
    },

  },

  components: {
      ProjectCard,
      SearchFilter,
      ProjectStageSelect,
      Evaluations
  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>