<template lang="pug">
.main
    ProjectModal(ref="projectModal")
    CompanyModal(ref="companyModal")
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu(v-if="!$route.query.extension")
            .container-xxl
                .row.no-gutters.submenu-inner
                    .col-7
                      .headline-submenu Inbox
                b-row(align-v="end").no-gutters.submenu-inner
                    //.col-md-12
                        .menu
                        
                            //router-link(to="/ecosystem/list").mr-5 Smart Inbox
                            //router-link(to="/ecosystem/list").mr-5 Suggestions
                            router-link(to="/ecosystem/list").mr-5 Imports
                            //router-link(to="/ecosystem/map").mr-5 Map
                            //router-link(to="/ecosystem/analytics").mr-5 Analytics
                            //router-link(to="/ecosystem/funding").mr-5 Funding
                            // router-link(to="/scout/analysis").mr-5 Analysis
                            // router-link(to="/scout/network").mr-5 Network
                            // router-link(to="/scout/map").mr-5 Map.menu

                                

    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
            
</template>

<script>
import CompanyModal from "@/components/CompanyModal.vue";
import ProjectModal from "@/components/ProjectModal.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import ProjectStageSelect from "@/components/ProjectStageSelect.vue";

import Config from "@/config";
import Vue from "vue";

export default {
  name: "SubmenuEcosystem",
  props: {
    title: String
  },

  data: function() {
    return {
      selectAll: false,
      selectedProjectStage: null
    };
  },

  watch: {
    selectedProjectStage: function(value) {
      console.log(value);
      if (value == "new") {
        this.showProjectModal();
      }
    }
  },


  methods: {
    addCompaniesToProject(selectedItem, selectedProjectStage) {
      let items = selectedItem.map(x => {
        return { organization_id: x._id, project_id: selectedProjectStage };
      });
      console.log(items, selectedProjectStage);
      this.$store.dispatch("start_loading");
      Vue.http
        .post(Config.config.base_host + "/project_organizations/bulk", items)
        .then(resp => {
          this.$store.dispatch("deselectAll");
          alert("Added " + resp.body.length + " companies to project");
          this.$store.dispatch("stop_loading");
        });
    },

    showProjectModal() {
      let res = this.$refs.projectModal.open(null, "ecosystem");
      console.log(res);
    },

    
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    map(text) {
      return text[0].toUpperCase() + text.slice(1);
    },

    showModal() {
      this.$refs.companyModal.open();
    },

    select() {
      if (this.selectAll) {
        this.$store.dispatch("selectAll");
      } else {
        this.$store.dispatch("deselectAll");
      }
    },

    addRecords: function() {
      this.$store.dispatch("addSelectedRecords");
    }
  },
  components: {
    CompanyModal,
    ProjectSelect,
    ProjectStageSelect,
    ProjectModal
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_vars.scss";

.filter-badge {
  padding: $pad/4;
  border: 1px solid $primary;
  color: $light;
  background-color: $primary;
  display: inline-block;
  opacity: 0.6;
}
</style>
