<template lang="pug">
  .home
    OnboardModal(ref="onboardModal")

    WListModal(ref="dashboardlistmodal", @saved="getWatchlists()")
    GeneralModal(ref="generalModal", @saved="saved_idea()")
    
    .row()
      .col-md-3
  
      

        div(style="max-width: 400px")
        
          //b 
            i.fas.fa-project-diagram.mr-2
            | Your projects

          //b-list-group(size="sm").mt-1
            b-list-group-item(v-for="item in this.wlists").d-flex.justify-content-between.align-items-center 
              b-link(@click="openList(item)") {{item.name}} 
              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                  template(v-slot:button-content)       
                    i.fas.fa-ellipsis-h.gray-info
                  b-dropdown-item(size="sm" @click="$refs.dashboardlistmodal.open(item, item.item_type)") Edit List
                    
                  b-dropdown-divider()
                  b-dropdown-item(size="sm" @click="removeWatchlist(item)")  Delete List

          div

            //div.mb-2 
              b Announcements
            
            //div().small.mb-2 
            
            
            //div(v-if="project")
              b-btn(variant="primary", @click="$refs.generalModal.open(null, project.general_stages[0], project)") Submit new idea

            //hr.mb-2
            b
              i.fas.fa-search.mr-2
              | Your saved searches
            .text-gray.small Save search to reactivate filters you use frequently or continue your search another time. 
            .mt-1(v-if="saved_queries && saved_queries.length == 0") You have not saved any queries yet. <a href="/ecosystem">Start exploring</a>
            b-list-group(size="sm").mt-1
            b-list-group-item(v-for="item in this.saved_queries").d-flex.justify-content-between.align-items-center 
              template(v-if="instance_config.app_config.base.version == 'health'")
                b-link(:to="'/health/health?saved_query_id=' + item._id", :data-name="item.name") {{item.name}}
              template(v-else)
                b-link(:to="'/ecosystem?saved_query_id=' + item._id", :data-name="item.name") {{item.name}}
              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                  template(v-slot:button-content)       
                    i.fas.fa-ellipsis-h.gray-info

                  b-dropdown-item(size="sm" @click="removeQuery(item)")  Delete search
          
          .mt-5
            div(style="max-width: 400px", v-if="projects && projects.length == 0")
        
              | You don't have joined any projects yet

            div(style="" v-if="projects")
              b 
                i.fas.fa-search.mr-2
                | Your joined projects
            
            div(style="max-width: 400px; max-height: 300px; overflow-y: scroll", v-if="projects")
              b-card(v-for="pt in this.$_.sortBy(projects, ['name'])", v-if="!pt.deleted_at", :data-name="pt.name").mt-1
                  
                .media
                  Logo(:name="pt.name", :size="20", :inline="true", :rounded="false").mr-1
                  .media-body 
                      b-link(:to="'/projects/'+ pt._id.split('::')[0]")
                          .mt-1 {{pt.name}}
          .mt-5
            a(@click="$refs.onboardModal.open()", href="javascript:void(0)").text-muted.small.mt-3 Show onboarding

      .col-md-6()
        b Your ecosystem updates 
        .small.float-right 
          b-link(:to="{path: '/news/browse'}") Show more news
          |
        b-card(style="max-height: calc(100vh - 180px); overflow-y: auto")
          .text-center(v-if="loading_news")
            b-spinner().ml-auto
          div(v-for="topic in topics" v-if="topic.relations && topic.relations.news && topic.relations.news.entries.length > 0").pb-5
            
            b.text-muted {{topic.name}}
            div(v-for="(entry, k) in topic.relations.news.entries" v-if="topic.relations.news")
              .media.mt-0(v-if="entry.doc_type == 'FeedEntryNews'").mt-2
                    
                a(:href="entry.link")
                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.source + ') center 0% / cover no-repeat;'").mr-2

                .media-body()
                    a(:href="entry.link", target="_blank")
                      
                        .truncate-2() {{entry.title}}
                      
                        //template(v-if="entry.source_data && entry.source_data.highlights")
                          .text-muted.small.desc(v-for="e in entry.source_data.highlights.full_text" v-html="e", v-show="desc_show")
                  
                        .text-muted.small() {{entry.created_at | utcFrom}} &dash; {{entry.source}} 
                        
                      

            div(v-if="topic && topic.relations && topic.relations.sections['foundings'] && topic.relations.sections['foundings'].length").mt-3.ml-4
              hr
              .text-muted.small {{topic.name}} - New companies
              
              div(v-for="entry in topic.relations.sections['foundings'].slice(0,3)")
                
                .media.mt-0().mt-3
                  
                    a(:href="entry.org_url")
                        div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                    .media-body()
                      a(:href="entry.org_url").small {{entry.org_name}} 
                      .small.truncate-2.text-muted.small {{entry.short_description}}
            
            div(v-if="topic && topic.relations && topic.relations.sections['funding_rounds'] && topic.relations.sections['funding_rounds'].length").mt-3.ml-4
              hr
              .text-muted.small {{topic.name}} - New funding rounds
              div(v-for="entry in topic.relations.sections['funding_rounds'].slice(0,3)")
                
                .media.mt-0().mt-3
                  
                    a(:href="entry.org_url")
                        div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                    .media-body()
                      
                      a(:href="entry.org_url").small {{entry.org_name}} - {{ucfirst(entry.investment_type.replace("_", " "))}} - {{entry.value}}
                      .small.truncate-2.text-muted.small {{entry.short_description}} 
                      .text-muted.small(v-if="info_show") {{entry.date | utcFrom}}

            div(v-if="topic && topic.relations && topic.relations.sections['aquisitions'] && topic.relations.sections['aquisitions'].length").mt-3.ml-4
              hr
              .text-muted.small {{topic.name}} - Acquistions
              div(v-for="entry in topic.relations.sections['aquisitions'].slice(0,3)")
                .media.mt-0().mt-3
                  
                    a(:href="entry.org_url")
                        div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                    .media-body()
                      a(:href="entry.org_url").small {{entry.org_name}} 
                      .small.truncate-2.text-muted.small {{entry.short_description}}
                      .text-muted.small(v-if="info_show") {{entry.date | utcFrom}}          
      
                 
                                
        
      .col-md-3
        div()
          b Latest team activity
          b-card(style="max-height: calc(100vh - 180px); overflow-y: auto")
            
         
            b-overlay(:show="$store.state.loading")     
              template(v-for="entry in feed")  
              
                .pb-4(v-if="entry.doc_type == 'FeedEntryActivity' && activity_types[entry.activity_type] && (entry.activity_type != 'comment_added' || entry.relations.comment_id)")
                  b-card(no-body).activity-card.ib-card.mt-1.pb-0.pl-3
                    .media
                      
                      User(:user="entry.relations.owner_id", :size="35").mr-3.ml-0.feed-image-small
                      .media-body
                        .text-muted.small {{entry.created_at | utcFrom}}
                        .text-muted.small 
                          b {{activity_types[entry.activity_type]}}
                  
                  b-card(v-if="entry.relations.organization_id").content-card.mt-2.mb-2
                      .media
                        
                        Logo(:web_entity="entry.relations.organization_id.web_entity", :rounded="false", :name="entry.relations.organization_id.name", :size="30").mr-1
                        .media-body
                          router-link(:to="'/ecosystem/orgs/'+entry.organization_id")
                            .row
                              .col-12

                                h6.text-truncate(style="padding-top: 1px") {{entry.relations.organization_id.name}}
                              .col-12
                              
                                  div.links.small
                                    a(:href="'http://'+entry.relations.organization_id.web_entity" target="_blank" v-if="entry.relations.organization_id.web_entity").mr-1 {{entry.relations.organization_id.web_entity}}
                                    a(:href="entry.relations.organization_id.twitter_url"  target="_blank" v-if="entry.relations.organization_id.twitter_url").mr-1  <i class="fab fa-twitter-square"></i>
                                    a(:href="entry.relations.organization_id.facebook_url"  target="_blank" v-if="entry.relations.organization_id.facebook_url").mr-1  <i class="fab fa-facebook-square"></i>
                                    a(:href="entry.relations.organization_id.linkedin_url"  target="_blank" v-if="entry.relations.organization_id.linkedin_url").mr-1  <i class="fab fa-linkedin"></i>
                      
                      
                      div(v-if="entry.activity_type == 'contact_added' || entry.activity_type == 'comment_added'")
                        hr.p-0.mb-0.mt-1
                        .mt-2
                          b.text(v-if="entry.relations.contact_id") {{entry.relations.contact_id.first_name}} {{entry.relations.contact_id.last_name}} 
                            <span v-if="entry.relations.contact_id.first_name || entry.relations.contact_id.last_name">-</span> {{entry.relations.contact_id.position}}
                          .text-muted(v-if="entry.relations.comment_id") {{entry.relations.comment_id.text}}
                          .text-muted(v-html="entry.legacy_text")
                      
                      div(v-if="entry.activity_type == 'tags_changed' && entry.relations.organization_id")
                        hr.p-0.mb-0.mt-1
                        .mt-2
                          span(v-for="tag in entry.relations.organization_id.tags").text-muted {{'#'+tag}} 

        
          
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import User from '@/components/User'
import Logo from '@/components/Logo'
import WListModal from '@/components/WListModal'
import GeneralModal from '@/components/GeneralModal'
import { mapFields } from 'vuex-map-fields';
import ThreeStateCheckbox from '@/components/ThreeStateCheckbox'
import OnboardModal from '@/components/OnboardModal'
export default {
  
  name: 'Dashboard',

  data: function () {
    return {
        hh: [],
        uid: null,
        project: null,
        topics: null,
        filters: {
          "explainer": true,
          "launch": true,
          //"management": true,
          "investment": true,
          //"acquistions": true,
          //"research": true,
          //"operations": true,
          "partner": true,
          "others": true
        },
        loading_news: false,
        feed: [],
        q: "",
        disabledFilterSelected: false,
        sims: null,
        activity_types: {
          "comment_added": "added a new comment",
          "tags_changed": "added some tags",
          "contact_added": "added a new contact",
          "project_added": "added a new project",
          "organization_added": "added a new organization",
          "organization_added_to_project": "added a new organization"
        },
        filter: [{"text": "Activities", "value": "activities"},{"text": "News", "value": "news"}],
        wlists: null,
        projects: null,
        saved_queries: null
    }
  },

  created: function () {
   
  },
 

  computed: {

        ...mapFields([
            'instance_config',
            'user'
        ]),
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getFeed();
    this.getWatchlists();
    this.getQueries();
    this.getProjects();
    this.getFollowTopic();
   
    if(this.$auth.user.is_onboarded == false) {
       this.$refs.onboardModal.open();
    }
   
  },

  methods: {  
     ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    getFollowTopic() {
      
        this.selectedIndustry = null;
       
        let params = {"topic_type": "topic", "bookmarked": true};
        let req = [];
        this.loading_news = true;
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
          
          resp.body.rows.forEach(it => {
            it.relations.news = null;
            it.relations.sections = {};
            req.push({"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": 7, "q":[],"sortBy":"pub_date","project_ids":null, "topic_ids":[it._id], "limit": 5, "page":1});
            
            Vue.http.post(`${Config.config.base_host}/topics_v1/sections`, {"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[],"sortBy":"_score","project_ids":null, "topic_ids":[it._id], "page":1}).then(res2 => {
              it.relations.sections = res2.body;
              
            });

          });

          this.topics = resp.body.rows;

          Vue.http.post(`${Config.config.base_host}/topics_v1/news_feed_bulk`, req).then(res2 => {

            res2.body.forEach(it => {
              this.topics.find(x => x._id == it.topic_id).relations.news = it.res;
            });
            this.loading_news = false;
              
              
          });

        }); 
      },

    getFeed() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/feed`, {q: this.q, type_filter: this.filterSelected}).then(res => {
        this.feed = res.body.splice(0,20);
        this.$store.dispatch("stop_loading");
      });
    },

    getSims() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/get_similar_ecosystem`).then(res => {
        this.sims = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    getWatchlists() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/watchlists`).then(res => {
        this.wlists = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    getQueries() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1`).then(res => {
        this.saved_queries = res.body;
        this.$store.dispatch("stop_loading");
      });
    },


    getProjects() { 
    
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/projects/search`, {"joined": true}).then(res => {
        this.projects = res.body;
        this.$store.dispatch("stop_loading");
      });
    
    },

  


    openList(wlist) {
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {filter: {"limit": 200, "watchlist_id": [wlist._id]}}).then(res => {
        this.$store.state.selectedItems = res.body.rows;
        //Object.assign(this.$store.state.query.filter, this.$store.state.query.filter_prototype);
        this.$forceUpdate();
        this.$store.state.query.filter.watchlist_id = [wlist._id];
        this.$router.push({path: "/ecosystem/list"});
        this.$store.dispatch("stop_loading");
      });

    },

    openListUseCase(wlist) {
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {"watchlist_id": [wlist._id], "normal_list": true}).then(res => {
        
        this.$store.state.sidebar_project.selected_list = res.body.rows;
        
        this.$store.state.usecase.filter.watchlist_id = [wlist._id];
        this.$router.push({path: "/topics/usecases"});
        this.$store.dispatch("stop_loading");
      });

    },

    removeWatchlist(wlist) {

       this.$bvModal.msgBoxConfirm("Are you sure to delete the watchlist?", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.delete(`${Config.config.base_host}/watchlists/${wlist._id}`).then(() => {
                      this.getWatchlists();
                      this.$bvToast.toast(wlist.name + " removed.", {
                        title: 'Delete',
                        variant: 'primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
            
      
        });


    },

    removeQuery(query) {

       this.$bvModal.msgBoxConfirm("Are you sure to delete this query?", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.delete(`${Config.config.base_host}/queries_v1/${query._id}`).then(() => {
                      this.getQueries();
                      this.$bvToast.toast(query.name + " removed.", {
                        title: 'Delete',
                        variant: 'primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
            
      
        });


    },


  },

  components: {
    User,
    Logo,
    WListModal,
    GeneralModal,
    ThreeStateCheckbox,
    OnboardModal
  }
}

</script>
<style lang="scss" scoped>

//@import '@/_vars.scss';

.media-body {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-card-news {
  max-width: 800px !important;
 
}

.content-card {
  max-width: 800px !important;
  border: 0;
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.2);
}

.activity-card {
  
  background-color: transparent;
  .card-body {
    padding-bottom: 0;
  }
}

.action {
  margin-left: 2*12px;
}

.float-right {
  .custom-control-inline {
    margin-right: 0 !important;
    margin-left: 1em !important;
  }
}





</style>