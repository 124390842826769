<template lang="pug">
  .search-filter(v-if="$store.getters.project")
    b-form(autocomplete="off")

        //FilterGroup(title="Search in" :collpased="true").mt-1
          b-form-group()
            b-check(checked) Ecosystem
            b-check(checked) Scouting Database

        FilterGroup(title="Stages", :collpased="true").mt-3
          v-select(id="stage"
                      :options="$store.getters.project.stages",
                      :multiple="true"
                      :select-on-tab="true"
                      v-model="stage").small
      
        FilterGroup(title="Keywords" :collpased="true").mt-3
          .small
             v-select(id="keyoword"
                      :options="[]",
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"
                      v-model="keywords", placeholder="Enter term and press enter")

        //FilterGroup(title="Locations", :collpased="true").mt-3
                
            b-form-group(id="input-group-city", label="Cities", label-for="input-2", label-size="sm")
                v-select(id="city"
                      :options="[]",
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"
                      v-model="city")

            b-form-group(id="input-group-region", label="Regions", label-for="input-2", label-size="sm")
                v-select(id="region"
                      :options="[]",
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"
                      v-model="region")
          
            b-form-group(id="input-group-country_code", label="Countries", label-for="input-2", label-size="sm")
                
                v-select(id="country_code"
                      :options="[]",
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"                   
                      v-model="country_code")
            
  
        .mt-3
          
              b-button(variant="secondary", @click="getData()", size="sm")#project-search-button.form-control Search in Project
          

</template>

<script>
// @ is an alias to /src

import Config from '@/config'


import FilterGroup from '@/components/FilterGroup'
import ProjectStageSelect from '@/components/ProjectStageSelect'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectFilter',

  data: function () {
    return {
      sources_filter: [{"text": "Ecosystem", "value": "ecosystem"}, {"text": "hy database", "value": "hy"}, {"text": "Crunchbase", "value": "cb"}]
    }
  },

  mounted: function() {
  
      this.host = Config.config.base_host;
      if(!this.selected) {
        this.selected = "No similarity search";
      }

       this.getData();
      
  },

  computed: {

    ...mapFields([
        'projects.selected',
        'projects.query.filter.stage',
        'projects.query.filter.keywords',
        'projects.query.filter.city',
        'projects.query.filter.country_code',
        'projects.query.filter.region',
        'projects.query.filter.project_id'
    ]),
  },
  
  methods: {
    
    getData() {
        this.$store.dispatch("projectSearch", this.$route.params.id);
        
    },

  },
  components: {
    FilterGroup,
    ProjectStageSelect
  }
}

</script>
<style lang="scss" scoped>
/*.search-filter {
    position: fixed;
    margin-top: -50px;
    z-index: 100000;
}*/
</style>
