<template lang="pug">

.project-card.h-100
    b-card(no-body, img-top).h-mouseover-parent.h-100
     
      router-link(:to="'/projects/'+project._id+'/dashboard'")
          //b-card-img(:src="get_image(project)")
        
      b-card-body.p-0
        .row
          .col-12
              .media
                template(v-if="project.custom_data && project.custom_data.client_url")
                  .p-2
                    Logo(:domain="project.custom_data.client_url", :size="50", :inline="true", :rounded="false", style).mr-2
                template(v-else)
                  Logo(:name="project.name", :size="50", :inline="true", :rounded="false", style).mr-2
                .media-body(style="min-height: 100px").align-middle
                  .pb-2
                
                    b-link(:to="'/projects/'+project._id+'/dashboard'", :data-name="project.name" variant="outline-secondary", size="sm", target="_top")
                      h5().pt-2 {{project.name}}

                    .text-muted.small(v-if="project.custom_data && project.custom_data.project_type") 
                      | {{project.custom_data.project_type.join(', ')}}

                    template(v-if="project.custom_data && project.custom_data.tags")
                      template(v-for="tag in project.custom_data.tags")
                        b-badge(variant="secondary").mr-1 {{tag}}
                  

                
      hr.m-0.p-0
      .p-3.pb-0
        .row(style="opacity: 0.4").text-gray
         
          .col-5.text-nowrap
            i.fas.fa-user-friends.mr-2
            .small.d-inline {{project.member_ids.length}} Members
          

          .col-5.text-nowrap
            i.fas.fa-user-edit.mr-2
            .small.d-inline  {{project.updated_at | utcFrom }}
          //.col-2
            i.fas.fa-eye.mr-2
            .small.d-inline 22
          //.col-2
            i.fas.fa-bookmark.mr-2
            .small.d-inline 33
          .col-2     
            .float-right            
                b-link(variant="link", v-if="$auth.is_bookmarked(project)", @click="$auth.remove_bookmark(project)")
                  <i class="fas fa-bookmark"></i>
                b-link(variant="link", v-if="!$auth.is_bookmarked(project)", @click="$auth.add_bookmark(project)")
                  <i class="far fa-bookmark"></i>
          

        template(v-if="project_organization")
          //b-form-group(label="Company Stage").mt-3
            v-select(id="stage"
                        :options="project.stages",
                        :multiple="false"
                        :select-on-tab="true"
                        v-model="project_organization.stage",size="sm", style="max-width: 200px").small
          
          template(v-if="!funnel_edit")
            .badge.badge-secondary.mr-1 {{project_organization.stage}}
          
          template(v-if="funnel_edit && project").mt-2
            b-select(v-model="project_organization.stage", :options="project.stages", size="sm", @change="input_event").mt-3
              template(v-slot:first)
                b-form-select-option(:value="'Not assigned'") Not assigned

          
            label.mt-4( @click="showHistory=!showHistory") Stage History <span v-if="!showHistory">(Show)</span>

            table.table(v-if="project_organization && project_organization.stage_history && showHistory").small
              template(v-for="(entry,i) in project_organization.stage_history")
            
                tr(v-if="!edit_mode[i]").h-mouseover-parent
                  td() {{entry.created_at.substring(0,10)}}
                  td() {{entry.from_stage}} →
                  td() {{entry.to_stage}}
                  td(style="width: 100px; height: 60px")
                    .float-right.h-mouseover-show
                      b-btn(size="sm", variant="link", @click="doeditMode(i)").small.text-muted.mr-1
                        i.fas.fa-pen
                      b-btn(size="sm", variant="link", @click="remove(i)").small.text-muted
                        i.fas.fa-times

                tr(v-if="edit_mode[i]")

                  td(colspan="4")
                    b-form-group(label="Date")
                      b-datepicker(v-model="entry.created_at" size="sm")
                    b-form-group(label="From Stage → To Stage")
                      b-select(:options="project.stages", v-model="entry.from_stage", size="sm").mt-1
                        template(v-slot:first)
                          b-form-select-option(:value="'Not assigned'") Not assigned
                      b-select(:options="project.stages", v-model="entry.to_stage", size="sm").mt-1
                    .float-right
                      b-btn(@click="cancel()", size="sm", variant="outline-primary").mr-1 Cancel
                      b-btn(@click="input_event()", size="sm", variant="outline-primary") Save


</template>

<script>
// @ is an alias to /src

//import Config from '@/config'

import Avatar from 'vue-avatar'

import Logo from "@/components/Logo"

export default {
  name: 'CompanySelect',
  props: {
    "project":  {
      default: null
    }, 
    "project_organization": {
      default: null
    },
    "collpased": {
      type: Boolean,
      default: false
    },
    "showHistory": { 
      type: Boolean,
      default: false
    },
    "funnel_edit": {
      type: Boolean,
      default: false
    },
    "selectMode": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null,
        edit_mode: {}
    }
  },

  watch: {
    project_organization() {

     if(this.project_organization && this.project_organization.stage_history && Array.isArray(this.project_organization.stage_history)) {
        this.project_organization.stage_history.forEach((x, i) => { 
          this.edit_mode[i] = false;
        });
     }
    }
  },

  mounted: function() {
     this.uid = this._uid;
     if(this.project_organization && this.project_organization.stage_history && Array.isArray(this.project_organization.stage_history)) {
        this.project_organization.stage_history.forEach((x, i) => { 
          this.edit_mode[i] = false;
        });
     }
    
     this.$forceUpdate();
     
  },

 
  methods: {

    doeditMode: function(i) {
      this.edit_mode[i] = true;
      this.$forceUpdate();
   },

    remove(i) {
         this.$bvModal.msgBoxConfirm("Are you sure to delete this history entry?", {buttonSize: 'sm'}).then((value) => {
          

            if(value) {
              this.$store.dispatch("start_loading");
              this.project_organization.stage_history.splice(i,1);
            
              this.input_event();
            }
            
        });
    },

    input_event() {
      
      this.$emit("funnel_changed", this.project_organization);
    },

    cancel() {
      
      this.$emit("cancel", this.project_organization);
    },
  
  },

  components: {
    Avatar,
    Logo
  }
}

</script>
<style scoped lang="scss">

</style>
