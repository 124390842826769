<template lang="pug">

.main
   
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
    
        .submenu
            
            .container-xxl

                .row.no-gutters.submenu-inner
                    .col-12
                            .row
                                .col-7    
                                    //.headline-submenu.mb-3 Hi {{$store.state.user.full_name}}
                                    .headline-submenu.mb-3 Welcome, {{$store.state.user.full_name}}
                                .col-5
                                    .float-right

                //b-row(align-v="end").no-gutters.submenu-inner
                    .col-md-12
                        .menu
                        
                            router-link(to="/inbox/list").mr-5 Dashboard
                            router-link(to="/inbox/list").mr-5 Your Inbox
                            router-link(to="/inbox/list").mr-5 Latest activity
                            router-link(to="/ecosystem/list").mr-5 News
                           
                            //router-link(to="/ecosystem/map").mr-5 Map
                            //router-link(to="/ecosystem/analytics").mr-5 Analytics
                            //router-link(to="/ecosystem/funding").mr-5 Funding
                            // router-link(to="/scout/analysis").mr-5 Analysis
                            // router-link(to="/scout/network").mr-5 Network
                            // router-link(to="/scout/map").mr-5 Map.menu

    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
  
          
        
</template>

<script>
export default {
  name: 'SubmenuDashboard',
  props: {
   
  },
  methods: {
   
  },
  computed: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
    

</style>
