<template lang="pug">
  .company-inline
    div(id="inline", :title="title", size="lg", ok-variant="outline-primary", cancel-variant="link", ref="modal-company-ref")
      b-form(size="sm", autocomplete="off")
        .form-row()
        
          .col-6
            b-form-group(id="exampleInputGroup1"  
                        label="Website"
                        size="sm"
                        label-for="exampleInput1"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.homepage_url.$error }")

                    b-form-input(id="homepage_url"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.homepage_url.$invalid"
                                size="sm"
                                
                                placeholder="domain.com", @change="check_duplicates(form)", v-model="form.homepage_url")
          
          .col-6
            b-form-group(id="exampleInputGroup1"
                        label="Company name"
                        label-for="exampleInput1",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }"
                        description="")
                    b-form-input(id="name"
                                type="text"
                                size="sm"
                                @change="check_duplicates(form)"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                placeholder="",  v-model="form.name")

        .form-row(v-if="duplicates && duplicates.length").mb-3
          .col-12
              b-card
                .small.text-muted Did you mean this company? Click to take over.
                a.small(v-for="it in duplicates.slice(0,1)", @click="form=it", href="javscript:void(0)") {{it.name}}, {{it.city}}, {{it.web_entity}}
                

        .form-row

          .col-12
            b-form-group(id="short_description-group"
                        label="Short description"
                        label-for="short_description",
                        )
              b-textarea(v-model="form.short_description", rows="2", id="short_description" size="sm", placeholder="Company description")
        
        .form-row.mt-3

          b-tabs(style="width: 100%" card, size="sm")
       
            b-tab(title="Info", id="tab-info")
              .form-row
                  .col-4
                      b-form-group(id="location_city-group"
                                  label="City"
                                  label-for="location_city"
                                  description="")
                              
                                b-form-input(id="location_city"
                                          type="text"
                                          size="sm"
                                          placeholder="", v-model="form.city")

                  .col-4
                      b-form-group(id="location_region-grup"
                                  label="Region / State"
                                  label-for="location_region"
                                  description="")
                           
                                b-form-input(id="location_region"
                                          type="text"
                                          size="sm"
                                          placeholder="",  v-model="form.region")
                  .col-4
                      b-form-group(id="location_region-group"
                                  label="Country Code"
                                  label-for="location_region"
                                  description="")
                                
                                b-form-input(id="location_region"
                                          type="text"
                                          size="sm"
                                          placeholder="",  v-model="form.country_code")

                                //CountrySelect(v-model="form.location_country_code", id="location_country_code", size="sm")
                 
              
              .form-row
                .col-4
                  b-form-group(id="founding_date-group"
                              label="Founding date"
                              label-for="founding_date"
                              description="")
                        b-input-group(size="sm")
                            
                            b-form-input(id="founding_date"
                                      type="text"
                                      size="sm", v-model="form.founding_date")

                .col-4
                  b-form-group(id="employee_count-group"
                              label="Employee count"
                              label-for="employee_count"
                              description="")
                        b-input-group(size="sm")
            
                            b-form-input(id="employee_count"
                                      type="text"
                                      size="sm", v-model="form.employee_count")

            
              b-form-group(id="description-group"
                        label="Long description"
                        label-for="description"
                        description="")
                  b-input-group(size="sm")
                      
                      b-textarea(v-model="form.description", id="description", rows="2", size="sm", placeholder="Company description")
              
              b-form-group(id="tags-group"
                        label="Tags"
                        label-for="tags"
                        description="")
                 
                      v-select(id="tag"
                                      :options="[]",
                                      :multiple="true"
                                      :taggable="true"
                                      
                                      
                                      placeholder="Enter tags and press enter"
                                      :select-on-tab="true"
                                      v-model="form.tags")


              b-form-group(id="categories-group"
                      label="Orgnization Types"
                      label-for="categories"
                      description="")
                
                    v-select(id="tag"
                                    :options="$store.state.instance_config.organization_types",
                                    :multiple="true"
                                    :taggable="false"
                                    
                                    
                                    placeholder="Enter type and press enter"
                                    :select-on-tab="true"
                                    v-model="form.categories")


            b-tab(title="Investments", size="sm", id="tab-investments")

              b-form-group(id="funding_total_origin_currencyGroup"
                          label="Total Funding"
                          label-for="funding_total_origin_currency-1"
                          description="")
                    b-input-group(size="sm")
                    
                      select(v-model="form.total_funding_currency_code", id="funding_total_origin_currency-1")
                        option(value="USD") $
                        option(value="EUR") €
                      
                      b-form-input(id="funding_total_origin-1"
                                type="number"
                                size="sm"
                                :placeholder="'Amount in ' + form.total_funding_currency_code", v-model="form.total_funding", style="max-width: 200px")
                    
                        
              b-form-group(id="exampleInputGroup1"
                          label="Investors"
                          label-for="exampleInput1"
                          description="").mt-2
                    
                      v-select(id="investors"
                              :options="[]",
                              :multiple="true"
                              :taggable="true"
                              :close-on-select="false"
                              :no-drop="true"
                              :select-on-tab="true"
                              placeholder="Investors (enter name and press enter)", 
                              v-model="form.investors")


            b-tab(title="Social / Profiles", size="sm", id="tab-profiles")
            
              .row.mt-3
                div().col-6

                        b-form-group(id="exampleInputGroup1"
                              label="LinkedIn"
                              size="sm"
                              label-for="linkedin_url"
                              description="")
                          
                          b-form-input(id="linkedin_url"
                                      type="text"
                                      size="sm",  v-model="form.linkedin_url")
                                      
                        b-form-group(id="exampleInputGroup1"
                                label="Crunchbase"
                                label-for="crunchbase_url"
                                description="")

                            b-form-input(id="crunchbase_url"
                                        type="text"
                                        size="sm", v-model="form.crunchbase_url")
                          
                        b-form-group(id="exampleInputGroup1"
                                label="Pitchbook"
                                size="sm"
                                label-for="pitchbook_url"
                                description="")
                            b-form-input(id="pitchbook_url"
                                        type="text"
                                        size="sm", v-model="form.pitchbook_url")
                            
                div().col-6

                        b-form-group(id="exampleInputGroup1"
                                label="Twitter"
                                label-for="twitter_url"
                                description="")

                            b-form-input(id="twitter_url"
                                        type="text"
                                        size="sm", v-model="form.twitter_url")


                        b-form-group(id="exampleInputGroup1"
                              label="Facebook"
                              label-for="facebook_url"
                              description="")

                          b-form-input(id="facebook_url"
                                      type="text"
                                      size="sm", v-model="form.facebook_url")
                       
                        
                        b-form-group(id="exampleInputGroup1"
                                label="AngelList"
                                size="sm"
                                label-for="angel_url"
                                description="")
                            b-form-input(id="angel_url"
                                        type="text"
                                        size="sm", v-model="form.angel_url")

            
            b-tab(title="GDPR", size="sm", id="tab-gdpr")
              .row.mt-3
                  b-table(:items="form.gdpr_reviews", size="sm").small
                
              label Add data review
              b-form(@submit.prevent="form.gdpr_reviews.push(Object.assign({}, new_gdpr_review))")
                .form-row
                  .col-4
                    b-input(v-model="new_gdpr_review.name", placeholder="Full name", size="sm", required)
                  .col-4
                    b-form-datepicker(v-model="new_gdpr_review.checked_at", required)
                  .col-4
                    b-btn(type="submit", size="sm", :disabled="!new_gdpr_review.checked_at || !new_gdpr_review.name") Add review
              
              .mt-3
              label Notification period in months
              b-input(type="number", v-model="form.gdpr_notify_every_months", size="sm", required, style="width: 200px") Month


    
        .float-right
          b-btn-group(size="sm")
            b-btn(variant="outline-secondary" @click="reject()") Reject
            b-btn(variant="primary"  @click="save()")#add-company-save {{ok_btn_text}}

</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
   props: {
    form: {
      default: () => {
        return {"domain": "", "name": "", total_funding_currency_code: "USD", selectedLists: [], contacts: [],  total_funding: 0};
      }
    }
  },
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        },
        homepage_url: {
            required,
            minLength: minLength(1)
        }
    }
  },

  watch: {
    '$props':{
      handler: function () { 
        this.check_duplicates(this.form);
      },
      deep: true
    }
  },

  data: function () {
    return {
        new_gdpr_review: {"checked_at": null, "name": ""},
        uid: null,
        title: "Add a new company",
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Company",
        duplicates: null
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.new_gdpr_review.name = this.$store.state.user.full_name;
  },

  methods: {

    check_duplicates(org) {
      this.duplicates = null;
      let org_to_check = Object.assign({}, org);
      org_to_check.web_entity = org_to_check.homepage_url;

      Vue.http.post(`${Config.config.base_host}/import/duplicate_check`, org_to_check).then(res => {
          this.duplicates = res.body;
      });
    },

    open(org) {
      
      if(org) {
        this.form = JSON.parse(JSON.stringify(org));
        if(!org.homepage_url && org.web_entity) this.form.homepage_url = org.web_entity;
        if(!org.description && org.registration_subject) this.form.description = org.registration_subject;
        this.ok_btn_text = "Save";
        this.title = "Edit " + org.name;
      } else {
        this.form = {"domain": "", "name": "", total_funding_currency_code: "USD", selectedLists: [], contacts: [],  total_funding: 0};
        this.duplicates = null;
      }


      this.$refs['modal-company-ref'].show()
    },
    
    reject() {
      this.$emit("rejected")
    },

    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/organizations`, this.form).then(res => {
          this.$emit("saved", res.body._id)
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
