<template lang="pug">
.tblrow.text-left(@click="hi(source)", :class="{active: source.selected}")
    .cell-s() 
        | {{source.id}} 
        b-badge(v-if="source.status && source.status != 'null'") {{source.status}}
    .cell() 
     
        | <b>{{ source.org.name }}</b> <br/> {{ source.org.city }}
        a(:href="''+source.org.homepage_url", target="_blank").small {{ source.org.homepage_url }}
        

</template>

<script>
  export default {
    name: 'item-component',
    props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default () {
          return {}
        }
      }
    },
    methods: {
        dispatch(componentName, eventName, ...rest) {
            let parent = this.$parent || this.$root
            let name = parent.$options.name

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent
                if (parent) {
                name = parent.$options.name
                }
            }
            
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(rest))
                 
            }
        },

        hi(source) {
            this.dispatch("list-view-import", "list_change_id", source['id'], source);
        }
    }
  }
</script>

<style lang="scss" scoped>

@import '@/_vars.scss';

.tblrow {
  border-top: 1px solid $gray-100;
  padding: 5px 0 5px 0;
  &.active{
      background-color: $gray-300 !important;
      //font-weight: bold;
  }
  display: flex;
  min-height: 20px;
  flex: 0 0 28px;
  &:hover {
      background-color: $gray-100;
  }
  .cell-s {
    flex: 1 1;
    text-align: left;
    flex-basis: 0;
    border: 0px;
   }
   .cell {
    flex: 5 5;
    text-align: left;
    flex-basis: 0;
    border: 0px;
    line-break: anywhere;
   }
.cell-r {
    flex: 5;
    text-align: left;
   
    border: 0px;
   }
}

</style>