<template lang="pug">
  
.home-s(class="flex-grow-1 h-100")
    ImportModal(ref="importModal")
    //.row
      //.col-md-2
        ProjectFilter()
      //.col-md-12()
       
          b-button-group(size="sm").mb-2
                b-btn(active, size="sm", @click="detailCols=!detailCols") Quick edit    
                //b-btn(size="sm") Website review   
    //.row
      .col-12
        .float-right
          b-btn(@click="browserWindow()", size="sm", variant="link").mr-1 Website review
          //b-btn(@click="save(po.project_id, po.organization_id, po, true)", size="sm") Save & Next
        
    .row.mt-2
      
      b-col(:cols="2")
        b-list-group()
          b-list-group-item.d-flex.justify-content-between.align-items-center 
            | <b>Imports</b>
            b-btn(size="sm", variant="outline-secondary", @click="showImportModal()")#import-btn Import
          b-list-group-item(v-for="it in imports", v-if="it.name", :active="job_open && it._id == job_open._id", style="cursor: pointer;").d-flex.justify-content-between.align-items-center().small

            div(@click="get_import(it)", href="javascript:void(0);") 
              | {{it.name}}
              .text-muted {{it.created_at | utcFrom}}
 

      b-col(:cols="5")
          
        b-card(v-if="!job_open").mb-1 Import a file or choose an dataset you've already imported.
         
        b-card(id="table-content", v-show="tableData && job_open")
            //b-card
          
            //b-form-checkbox-group(v-model="selectedFields", :options="fieldOptions")
            //vue-virtual-table(
              :config="tableConfig"
              :data="tableData"
              :height="calcH()"
              :itemHeight="30"
              :minWidth="10"
              :selectable="true"
              :enableExport="true"
              v-on:changeSelection="onRowSelected")

    
            #tbl.tbl
              .tblrow.font-weight-bold.small
                .cell-s Id
                .cell Company
                
              virtual-list(style="overflow-y: auto; height: 100%; width: 100%;"
                :data-key="'id'"
                :keeps="100"
                :data-sources="tableData"
                :data-component="itemComponent"
                
                ).small
              


              //b-table(ref="table", 
                responsive
                hover 
                selectable
                :items="myProvider", 
                :fields="fields", small, 
                :per-page="perPage", 
                select-mode="single",
                :current-page="currentPage"
                @row-selected="onRowSelected"
                
                
                ).mt-2
                template(v-slot:head()="scope")
                  .text-nowrap {{ scope.label }}
               
                template(v-slot:cell(org.name)="row")
                  .text-nowrap.max-width-200
                    .d-inline-block(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + row.item['org.web_entity'] + ') center 0% / cover no-repeat;'").mr-1
                    b-link(:to="'/ecosystem/orgs/'+row.item['org.id']") {{row.item['org.name']}}
                
                template(v-slot:cell(actions)="row")
                  b-link(size="sm" @click="open(row.item)") Details
    
      b-col(:cols="5")
        #max-h(v-if="selected_import")
          
          //b-card(v-for="dup in selected_import.dup_orgs")
            .row
              .col-6
                .text-muted Do you mean?
                b.mt-1 {{dup.name}}
                .text-muted.small  {{dup.web_entity}}
              .col-6
                b-button-group(size="sm").float-right
                  b-btn(size="sm", variant="outline-secondary") Yes
                  b-btn(size="sm", variant="outline-secondary") No, add new
          
          b-card()
              b-overlay(:show="$store.getters.loading" size="sm").small
                CompanyInline(:form="selected_import.org", @saved="saved", @rejected="rejected")
              
          //
            div(v-if="po")
              b-card()
                a(:href="'/ecosystem/orgs/' + po.relations.organization_id._id")
                  h6 {{po.relations.organization_id.name}}
                div
                  a(:href="po.relations.organization_id.homepage_url" target="_blank" v-if="po.relations.organization_id.homepage_url")#company-profile-web_entity.mr-1.small {{po.relations.organization_id.web_entity}} 
                  a(:href="po.relations.organization_id.twitter_url" target="_blank" v-if="po.relations.organization_id.twitter_url").mr-1  <i class="fab fa-twitter-square"></i>
                  a(:href="po.relations.organization_id.facebook_url" target="_blank" v-if="po.relations.organization_id.facebook_url").mr-1  <i class="fab fa-facebook-square"></i>
                  a(:href="po.relations.organization_id.linkedin_url" target="_blank" v-if="po.relations.organization_id.linkedin_url").mr-1  <i class="fab fa-linkedin"></i>
                .text-muted.small(v-if="po.relations.organization_id.short_description") {{po.relations.organization_id.short_description.substring(0,400)}} ...
                //.mt-3
                  b-select(v-model="po.relations.project_id.stage", :options="project.stages", size="sm", @change="save(po.project_id, po.organization_id, po)")
                  hr.mt-1.mb-0
                  UserSelect(v-if="po.relations", v-model="po.relations.account_owner_id", @input="save(po.project_id, po.organization_id, po)")

              div(v-if="po && $store.state.instance_config")
                SheetEmbedded(:organization_id="po.relations.organization_id._id", :configuration="$store.state.instance_config").small.mb-5

              div(:id="k" v-for="(fields, k) in custom_groups" v-if="po").small.mt-1

                label(id="project") {{k}}
                b-card()
                  
                  .mb-3(v-for="field in fields")
                    b-form-group(label-cols-sm="4", :label="field.label")
                    
                      template(v-if="field.data_type=='textarea'")
                        b-textarea(v-model="po.custom_data[field.name]", :placeholder="field.name['description']")
                      template(v-if="field.data_type=='text'", :placeholder="field.name['description']")
                        b-input(v-model="po.custom_data[field.name]")
                      template(v-if="field.data_type=='select'")
                        v-select(v-model="po.custom_data[field.name]", :options="field.options", :placeholder="field.name['description']")
                      template(v-if="field.data_type=='date'")
                        b-form-datepicker(v-model="po.custom_data[field.name]", :placeholder="field.name['description']")
                      template(v-if="field.data_type=='money_amount'")
                        currency-input(v-model="po.custom_data[field.name]", :placeholder="field.name['description']", )
                      template(v-if="field.data_type=='slider'") 
                        b-input-group(style="max-width: 400px", size="sm")
                          b-form-input(type="range", v-model="po.custom_data[field.name]", size="sm", :min="1", :step="1", :max="10")
                          b-input(v-model="po.custom_data[field.name]", :disabled="true", style="max-width: 40px")
        
                  .mt-3.float-right
                    b-btn(variant="primary", size="sm", @click="save(po.project_id, po.organization_id, po)") Save
        

</template>

<script>
// @ is an alias to /src
import ImportModal from '@/components/ImportModal';
import Config from "@/config";
import Vue from "vue";
import ProjectFilter from "@/views/projects/ProjectFilter";
import OrgView from "@/components/OrgView.vue";
import CompanyInline from "@/components/CompanyInline.vue";
import SheetEmbedded from "@/views/orgs/SheetEmbedded.vue";
import UserSelect from '@/components/UserSelect.vue'
import VirtualList from 'vue-virtual-scroll-list'
import { mapFields } from "vuex-map-fields";
import Item from '@/views/inbox/Item'

export default {
  name: "list-view-import",

  data: function() {
    return {

      imports: null,

      itemComponent: Item,
      
      otherDataAssginToItemComponent: 'The Progressive JavaScript Framework',
      job_open: null,
      menuHidden: false,
      detailCols: false,
      project_organizations: null,
      project: null,
      po: null,
      ctx: {"sortBy": "name", "sortDesc": false, "limit": 2000},
      reviewMode: false,
      selectedFields: ["org.name"],
      bookmark: null,
      tableConfig: [],
      data: null,
      tableData: [],
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Name",
          //stickyColumn: true
          //variant: 'secondary'
        },
        {
          key: "actions",
          //sortable: true,
          //label: "Name",
          //stickyColumn: true
          //variant: 'secondary'
        }
      ],
      custom_groups: {},
      perPage: 20,
      total_rows: null,
      currentPage: 1,
      s: {},
      q: "",
      w1: null,
      query: "",
      uuid: null,
      event_results: [],
      selected_import: null,
      selected: [],

  
    };
  },
  
  created: function(){
      this.$on("list_change_id", (id) => {
        this.open(id);
      });
  },

  mounted: function() {
    
    this.host = Config.config.base_host;
    //this.$store.dispatch("projectSearch", this.$route.params.id);
    //this.get_data();
    this.get_imports();
    console.log(document.getElementById("tbl"));
    document.getElementById("tbl").style.height = (window.innerHeight-280) + "px";
    document.getElementById("max-h").style.height = (window.innerHeight-300) + "px";
    document.getElementById("max-h").style.maxHeight = (window.innerHeight-300) + "px";
    
  },

 
  computed: {
    fieldOptions: function() {
      return this.fields.map(x => {
        return { text: x.label ? x.key : x.key, value: x.key };
      });
    },
    ...mapFields([])
  },

  methods: {
    saved(e) {
      this.$store.dispatch("start_loading");
     console.log(e);
     Vue.http
        .put(
          `${Config.config.base_host}/imports/${this.job_open._id}/${this.selected_import.id}/imported`
        )
        .then(res => {
          this.selected_import.status = res.body.status;
         
          console.log(this.selected_import, res.body);
          this.$forceUpdate();
          this.$store.dispatch("stop_loading");
        });
    },

    rejected(e) {
      this.$store.dispatch("start_loading");
     console.log(e);
     Vue.http
        .put(
          `${Config.config.base_host}/imports/${this.job_open._id}/${this.selected_import.id}/rejected`
        )
        .then(res => {
          this.selected_import.status = res.body.status;
          this.$forceUpdate();
          this.$store.dispatch("stop_loading");
        });
    },

    showImportModal() {
        this.$refs.importModal.open();
    },

     browserWindow() {
        const width = window.screen.width / 3;
       
        this.w1 = window.open(window.location.protocol + "//" + window.location.host + "/projects/redirect?url="  + "http://" +  this.po.relations.organization_id.web_entity, "w1", "width="+width+",height="+window.innerHeight);

      },

    showWindow() {
       
        if(this.w1) {
          this.w1.location.href = window.location.protocol + "//" + window.location.host + "/projects/redirect?url=" + "http://" + this.po.relations.organization_id.web_entity;
        }
      
    },


  
    getMapping(name, data_fields) {
      name = name.replace("project.custom_data.", "")
      console.log(name,  data_fields.filter(x=>x.name == name)[0])
      return data_fields.filter(x=>x.name == name)[0].label;
    },

  get_imports() {
    this.$store.dispatch("start_loading");
     Vue.http
        .get(
          `${Config.config.base_host}/imports`
        )
        .then(res => {
          this.imports = res.body;
          this.$store.dispatch("stop_loading");
        });
  },

   get_import(job) {
      this.$store.dispatch("start_loading");
      this.job_open = job;
     Vue.http
        .get(
          `${Config.config.base_host}/imports/${job._id}`
        )
        .then(res => {
          this.data = res.body;
          res.body.data_mapped.forEach((x)=>{x.selected=false;})
          this.tableData = res.body.data_mapped;
          this.$store.dispatch("stop_loading");
        });
  },

  


    open(id) {
      this.tableData.forEach((item) => {
        
         if(item['id'] == id) {
            this.selected_import = Object.assign({}, item);
            item.selected = true;
            this.selected_import.selected = true;
            this.selected_import.status = (' ' + this.selected_import.status);
   
         }  else {
           item.selected = false;
           item.status = (' ' + item.status).slice(1);
         }
        });
    },


  titleCase(string) {

      var sentence = string.toLowerCase().split(" ");
      for(var i = 0; i< sentence.length; i++){
         sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
  
       return sentence;
   },

    get_project_org(po_id) {
      
      Vue.http
        .get(
          `${Config.config.base_host}/project_organizations/${po_id}`
        )
        .then(res => {
          this.po = res.body;
          this.project = this.po.relations.project_id;
          this.showWindow();
          this.custom_groups = {};
          if (!this.project.data_fields_project_organization) {
            this.project.data_fields_project_organization = [];
          }
          this.project.data_fields_project_organization.forEach(x => {
            if (!this.custom_groups[x.group]) this.custom_groups[x.group] = [];
            this.custom_groups[x.group].push(x);
          });

          this.$forceUpdate();
          
        });
    },

    save(project_id, org_id, po, next=false) {
      
      Vue.http
        .put(
          `${Config.config.base_host}/project_organizations/${project_id}::${org_id}`,
          po
        )
        .then(() => {
          if(next) {
             let index = -1;
             this.tableData.forEach((item) => {
               
                if(item['project.id'] ===  `${project_id}::${org_id}`) {
                   index = this.tableData.indexOf(item);
                }
              });
             
             this.open(this.tableData[index+1]['project.id']);
          }
           
        });
    }
  },
  components: {
    OrgView,
    ProjectFilter,
    SheetEmbedded,
    UserSelect,
    VirtualList,
    ImportModal,
    CompanyInline
  }
};
</script>
<style lang="scss" scoped>

@import '@/_vars.scss';

.feed-image {
  width: 10px;
  height: 10px;
}

#max-h {
  overflow-y: scroll;
  overflow-x: hidden;
}

.tbl {
  display: flex;
  flex-direction: column;

  .tblrow {
  display: flex;
  min-height: 20px;
  flex: 0 0 28px;
  &:hover {
      background-color: $gray-100;
  }
  .cell-s {
    flex: 1 1;
    text-align: left;
    border: 0px;
   }
   .cell {
    flex: 5 5;
    text-align: left;
    border: 0px;
   }
}

}

.vue-affix {
  width: 600px;
}

.item-cell-inner {
  justify-content: left;
}

.max-width-200 {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.scroller {
  overflow-y: scroll;
  height: 600px;
}



</style>