<template lang="pug">
  .topic2-modal
    b-modal(id="topic2-modal", :title="title", size="lg", ok-variant="outline-primary", cancel-variant="link", ref="general-ref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()

          .col-7
            b-form-group(id="name-group"  
                        label="Name"
                        size="sm"
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                    b-form-input(id="name"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                size="sm"
                                placeholder="", v-model="form.name")
          
          .col-5
                 

                  ImageSelect(v-model="form.image_url_meta", id="image").mt-3

          //.col-12
             
              b-form-group(id="name-group"  
                          label="Industry"
                          size="sm"
                          label-for="industy"
                          description="",
                          v-bind:class="{ 'form-group--error': $v.form.name.$error }")
                      TaxonomySelect(topic_type="industry", v-model="form.parent_ids")
        
        .form-row()
          .col-12
            b-form-group(id="description-group"
                        label="Description"
                        label-for="exampleInput1",
                   
                        description="")
                    b-textarea(id="description"
                                type="text"
                                size="sm"
                                placeholder="", @change="", v-model="form.description")
          

          .col-12
            b-form-group(id="description-group"
                        label="Target Group"
                        label-for="exampleInput1",
                   
                        description="")

                    b-textarea(id="description"
                                type="text"
                                size="sm"
                                placeholder="", @change="", v-model="form.custom_data.target_group")


          .col-12
            b-form-group(id="stages-group"
                          label="Keywords"
                          label-for="exampleInput1"
                          description="").mt-2
                      
              TopicKeywordSelect(id="keyword-select", v-model="form.keywords") 

        
      template(slot="modal-footer")
            i(v-if="project") Add to project "{{project.name}}" {{stage}}
            b-btn(variant="outline-secondary" @click="$refs['general-ref'].hide(false)") Close
            b-btn(variant="primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import ImageSelect from '@/components/ImageSelect'

import TaxonomySelect from '@/components/TaxonomySelect'
import Config from '@/config'
import Vue from 'vue'

import { mapFields } from 'vuex-map-fields';

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
      
        uid: null,
        title: "Add",
        form: {"description": "", custom_data: {}, parent_ids: [], "name": "", member_ids: [], owner_id: null, stages: ["Watch", "Review", "Closed", "Rejected"], status: 'Ongoing'},
        options: [],
        stage: null,
        submitStatus: null,
        ok_btn_text: "Submit"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },


 computed: {
      
      ...mapFields([
          'project.project', 
          'instance_config'
      ]),

  },

  methods: {

    open(topic, stage, project = null) {
      
      this.stage = stage;

      if(project) {
        this.project = project;
      }
      
      if(!stage) this.stage = this.project.general_stages[0];

      if(topic) {
        this.form = JSON.parse(JSON.stringify(topic));
        this.ok_btn_text = "Save";
        this.title = "Edit " + topic.name;
        this.form.topic_id = this.$route.params.id
      } else  {
        this.form = {"description": "", "name": "", parent_ids: [], keywords: [], "topic_id": this.$route.params.id, custom_data: {}};
        this.title = "Add " + this.project.general_name;
      }
      
      this.$refs['general-ref'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        
        if(this.form._id){
          Vue.http.put(`${Config.config.base_host}/topics_v1/${this.form._id}`, this.form).then((res) => {
            this.$emit("saved", res);
            this.$refs['general-ref'].hide();
          });
        } else {
          if(this.project) this.form.project_id = this.project._id;
          if(this.stage) this.form.stage = this.stage;
          this.form.topic_type = "general";
          Vue.http.post(`${Config.config.base_host}/topics_v1`, this.form).then((res) => {
            
            this.$emit("saved", res);
            this.$refs['general-ref'].hide();
          });
        }
        
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    TaxonomySelect,
    ProjectStageSelect,
    ImageSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
